import {X} from "@styled-icons/boxicons-regular/X";

const Modal = ({title, content, handleConfirm, handleClose}) => {
  return (
    <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center">

      <div className="absolute w-full h-full bg-gray-900 opacity-50"></div>

      <div className="bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div className="py-4 text-left px-6">
          <div className="flex justify-between items-center pb-3">
            <p className="text-2xl font-bold">{title}</p>
            <button
              className={"text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"}
              onClick={handleClose}
            >
              <X/>
              <span className="sr-only">Close Popup</span>
            </button>
          </div>
          <p>{content}</p>

          <div className="mt-4 flex justify-end">
            <button className="btn-s btn-gray" onClick={handleClose}>Cancel</button>
            <button className="btn-s btn-red" onClick={handleConfirm}>Save</button>
          </div>
        </div>
      </div>

    </div>
  )
};

export default Modal;
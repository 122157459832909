import PropTypes from "prop-types";

const NamePlate = ({title, buttons}) => {
  return (
    <div className={"flex-row content-center ml-auto mr-auto"}>
      <div className={"float-left"}>
        <h4>{title}</h4>
      </div>
      <div className={"float-right"}>
        {buttons.map((button) => {return button})}
      </div>
    </div>
  )
}

NamePlate.propTypes = {
  title: PropTypes.string.isRequired,
  //menu: PropTypes.any.isRequired,
  buttons: PropTypes.array
}
export default NamePlate
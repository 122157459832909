const Input = ({label, id, name, type, register, required, errors}) => (
  <div className="col-span-2">
    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor={id}>{label}</label>
    <input
      id={id}
      name={name}
      type={type}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
      {...register(name, {required: required && 'This field is required'})}
    />
    {errors[name] && <div>{errors[name].message}</div>}
  </div>
);

export default Input;
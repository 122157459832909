import NamePlate from "../components/Nav/NamePlate";
import Popup from "../components/Popup/Popup";
import {useState} from "react";
import Modal from "../components/Modal/Modal";
import Button from "../components/Button/Button";
import List from "../components/List/List";
import Pagination from "../components/List/Pagination";
import Input from "../components/Input/Input";
import {useForm} from "react-hook-form";
import Textarea from "../components/Input/Textarea";

const Menu = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const {register, handleSubmit, formState: {errors}} = useForm();
  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => setShowAddModal(false);
  const openDelModal = () => setShowDelModal(true);
  const closeDelModal = () => setShowDelModal(false);

  const buttonList = [
    <Button key={"add"} className={"btn-s btn-solid"} click={() => {
      openAddModal()
    }}>Add</Button>,
    // <Button key={"modify"} className={"btn-m btn-teal"}>Modify</Button>,
    <Button key={"del"} className={"btn-b btn-gray"} click={() => {
      openDelModal()
    }}>Del</Button>,
    // <Button key={"del"} className={"btn-s btn-solid"} click={() => {openDelModal()}}>Del</Button>
  ]

  return (
    <>
      <NamePlate
        title={"Menu"}
        menu={[]}
        buttons={buttonList}
      />
      <div>
        <List></List>
      </div>
      <div>
        <Pagination></Pagination>
      </div>

      {showAddModal &&
        <Popup
          title={"Add"}
          handleClose={() => closeAddModal()}
          buttons={[
            <Button key={"save"} className={"btn-s btn-red"} click={() => {
              alert("s")

              handleSubmit(onSubmit)
            }}>Save</Button>
          ]}
        >
          <form id="form" onSubmit={handleSubmit(onSubmit)}
                className="grid gap-4 mb-4 grid-cols-2"
          >
            <Input
              label="Name"
              id="name"
              name="name"
              register={register}
              required
              errors={errors}
            />

            <Textarea
              label="Description"
              id="description"
              name="description"
              rows={2}
              register={register}
              errors={errors}
            />

            <Input
              label="Url"
              id="url"
              name="url"
              register={register}
              errors={errors}
            />

            <Input
              label="Sort"
              id="sort"
              name="sort"
              register={register}
              errors={errors}
            />

            <button type="submit">Submit</button>

          </form>

        </Popup>}
      {showDelModal &&
        <Modal
          title={"삭제"} content={"삭제하시겠습니까?"}
          handleConfirm={() => {
            alert("삭제");
            closeDelModal()
          }}
          handleClose={() => closeDelModal()}/>
      }

    </>
  )
}

export default Menu
import PropTypes from "prop-types";
import {X} from "@styled-icons/boxicons-regular/X";

const Popup = ({title, children, handleClose, buttons}) => {
  return (
    <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center">

      <div className="absolute w-full h-full bg-gray-900 opacity-50"></div>
        <div
             className={"bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto p-4"}>
          <div className={"flex justify-between items-center pb-3"}>
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {title}
            </h3>
            <button
              className={"text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"}
              onClick={handleClose}
            >
              <X/>
              <span className="sr-only">Close Popup</span>
            </button>
          </div>

          <div className="p-4 md:p-5 space-y-4">
            {children}
          </div>
          <div className="mt-4 flex justify-end">
            {buttons && buttons.map((button) => {
              return button
            })}
            <button className="btn-s btn-gray" onClick={handleClose}>Cancel</button>
          </div>

        </div>
    </div>
  )
}

Popup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  buttons: PropTypes.array
}

export default Popup;
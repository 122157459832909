const Textarea = ({label, id, name, rows, register, required, errors}) => (
  <div className="col-span-2">
    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor={id}>{label}</label>
    <textarea
      id={id}
      name={name}
      rows={rows||4}
      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      {...register(name, {required: required && 'This field is required'})}
    />
    {errors[name] && <div>{errors[name].message}</div>}
  </div>
);

export default Textarea;